import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import ButtonUnderlined from '../ButtonUnderlined';
import convertHtml from '../../utils/convertHtml';
import LazyImage from '../lazy-image';
import Label from '../Label';
import StandardPost from '../Insights/standardPost';
import { Link } from 'gatsby';

const FeaturedPostContainer = styled.div`
  width: 100%;
  display: flex;
  height: 325px;
  margin-bottom: 135px;

  @media (max-width: 1000px) {
    height: 255px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 90%;
    padding-left: 0px;
    margin-left: 5%;
  }
  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

const ImageContainerOuter = styled(motion.div)`
  width: 530px;
  min-width: 530px;
  height: 325px;
  margin-right: 60px;
  overflow: hidden;
  background-color: #212121;

  @media (max-width: 1000px) {
    width: 50%;
    margin: 0;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: auto;
  }
`;

const ImageContainer = styled(motion.div)`
  width: 530px;
  height: 325px;
  margin-right: 60px;
  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    width: 100%;
    height: auto;
  }
`;

const MetaData = styled.div`
  @media (max-width: 1000px) {
    width: calc(50% - 50px);
    padding-left: 50px;
  }
`;

const Title = styled(motion.div)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    margin-bottom: 10px;
  }
`;

const Subtitle = styled(motion.div)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-bottom: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 1000px) {
    margin-bottom: 10px;
  }
`;

const LabelContainer = styled(motion.div)`
  margin-bottom: 30px;
  margin-top: 30px;
  @media (max-width: 1000px) {
    margin-bottom: 10px;
    margin-top: 0px;
  }
`;

const ButtonContainer = styled(motion.div)``;

const StandardPostMobileContainer = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
`;

const ImageAnim = {
  hidden: { x: -20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
    },
  },
};

const LabelAnim = {
  hidden: { x: -20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.2,
    },
  },
};

const TitleAnim = {
  hidden: { x: -20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.3,
    },
  },
};

const SubtitleAnim = {
  hidden: { x: -20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.4,
    },
  },
};

const ButtonAnim = {
  hidden: { x: -20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.5,
    },
  },
};

export class FeaturedPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={50}
      >
        <React.Fragment>
          <FeaturedPostContainer>
            <Link to={this.props.post.url}>
              <ImageContainerOuter
                variants={ImageAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                <ImageContainer
                  whileHover={{ scale: 1.025, opacity: 0.7 }}
                  transition={{ ease: 'easeOut', duration: 0.3 }}
                >
                  {this.props.post.data.featured_image && (
                    <LazyImage {...this.props.post.data.featured_image} />
                  )}
                </ImageContainer>
              </ImageContainerOuter>
            </Link>
            <MetaData>
              <LabelContainer
                variants={LabelAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                <Label
                  color={this.props.color}
                  text={this.props.post.data.post_category}
                />
              </LabelContainer>
              <Link to={this.props.post.url}>
                <Title
                  dangerouslySetInnerHTML={convertHtml(
                    this.props.post.data.blog_title.text
                  )}
                  variants={TitleAnim}
                  initial="hidden"
                  animate={this.state.visible ? 'visible' : 'hidden'}
                />
              </Link>

              <Subtitle
                dangerouslySetInnerHTML={convertHtml(
                  this.props.post.data.post_excerpt.text
                )}
                variants={SubtitleAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              />
              <ButtonContainer
                variants={ButtonAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                <ButtonUnderlined
                  color={this.props.color}
                  buttonText={'Read More'}
                  link={this.props.post.url}
                  gatsbyMode={true}
                />
              </ButtonContainer>
            </MetaData>
          </FeaturedPostContainer>
          <StandardPostMobileContainer>
            <StandardPost
              post={this.props.post}
              index={this.props.index}
              activeCategory={this.props.activeCategory}
              color={this.props.color}
            />
          </StandardPostMobileContainer>
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default FeaturedPost;
