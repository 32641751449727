import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import Moment from 'react-moment';
import FeaturedPost from '../Insights/featuredPost';
import StandardPost from '../Insights/standardPost';
const PostContainerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 27px;
  @media (max-width: 750px) {
    margin-bottom: 50px;
    font-size: 22px;
  }
`;

const PostContainer = props => {
  let posts = props.data.sort(function compare(a, b) {
    var dateA = new Date(a.data.post_date);
    var dateB = new Date(b.data.post_date);
    return dateA - dateB;
  });

  if (props.activeCategory !== 'all') {
    posts = posts.reverse();
  }

  const filtered = useMemo(() => {
    return posts.filter(
      post => post.data.post_category === props.activeCategory
    );
  }, [props.data]);
  if (!filtered.length && props.activeCategory !== 'all') {
    return <NoData>No Posts were found.</NoData>;
  }

  if (props.activeCategory === 'all') {
    props.data.reverse();
    return (
      <PostContainerContainer>
        {posts.map((post, index) => (
          <React.Fragment>
            {index === 0 ? (
              <FeaturedPost
                post={post}
                activeCategory={props.activeCategory}
                index={index}
                color={props.color}
              />
            ) : (
              <StandardPost
                post={post}
                index={index}
                activeCategory={props.activeCategory}
                color={props.color}
              />
            )}
          </React.Fragment>
        ))}
      </PostContainerContainer>
    );
  } else {
    return (
      <PostContainerContainer>
        {filtered.map((post, index) => {
          return (
            <React.Fragment>
              {index === 0 ? (
                <FeaturedPost
                  post={post}
                  activeCategory={props.activeCategory}
                  index={index}
                  color={props.color}
                />
              ) : (
                <StandardPost
                  post={post}
                  index={index}
                  activeCategory={props.activeCategory}
                  color={props.color}
                />
              )}
            </React.Fragment>
          );
        })}
      </PostContainerContainer>
    );
  }
};
export default PostContainer;
